import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class PassengersService {
  passengerId = null;

  details = ({
    regDate: '',
    status: ''
  });

  constructor(private restApiService: RestApiService) { }

  getPassengersCount(): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/counts', 'page-center');
  }

  activeDeactivePassenger(data, id?: null): Observable<any> {
    if (id !== null && id !== '' && id !== undefined) {
      return this.restApiService.put('', '/secure/passengers/' + id + '/status?active=' + data, 'page-center');
    }
  }

  getPassengerDriverDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/' + id, 'page-center');
  }

  getBookingDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/booking/' + id, 'page-center');
  }

  getMemberLastSync(): Observable<any> {
    return this.restApiService.get('', '/secure/member-provider/updatedate/Members', 'page-center');
  }

  communicateData(data) {
    if (data !== null) {
      this.details.regDate = data.registrationDateStr;
      this.details.status = data.status;
    }
  }

  updateWallet(id, data): Observable<any> {
    return this.restApiService.post('', `/secure/wallet/update/${id}`, data, 'page-center');
  }

}
